import styled from 'styled-components'
import ReactModal from 'react-modal'
import { themeGet } from 'styled-system'
import CloseButton from '../../CloseButton'

export const ModalContentWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
`

export const StyledReactModal = styled(ReactModal).attrs(
  ({ isModalOpen, modalClose }) => ({
    isOpen: isModalOpen,
    onRequestClose: modalClose,
    overlayClassName: 'Overlay',
  }),
)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  // Allows for scrolling on screens with smaller heights or when zoomed in
  @media screen and (max-height: 500px) {
    max-width: 100vw;
    align-items: flex-start;
    overflow-y: auto;
  }

  ${ModalContentWrapper} {
    background-color: ${themeGet('colors.white')};
    border-radius: 0.75rem;
    display: flex;
    line-height: 1.5rem;
    width: 29.6875rem;
    outline: none;
    -webkit-font-smoothing: antialiased;
    position: relative;

    ${themeGet('breakpoints.tablet')} {
      max-width: calc(100% - 1.25rem);
    }

    ${themeGet('breakpoints.mobile')} {
      max-width: 100%;
    }
  }

  ${CloseButton} {
    color: ${themeGet('colors.gray600')};
    padding: 0.5rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
`
