import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AssignSchool from './component'

const AssignSchoolContainer = props => {
  const {
    onSelection,
    siteData: { country, region, city },
  } = props

  const [hasRegionData, setHasRegionData] = useState(true)
  const [netSuiteCustomerData, setNetSuiteCustomerData] = useState([])

  const handlers = {
    getNetSuiteCustomerData: data => {
      setNetSuiteCustomerData(data)
      // have to return the data to allow the options to be populated
      return data
    },

    handleChange: event => {
      const name = event.target.name
      const value = event.target.value
      const newState = {
        [name]: value,
      }

      if (name === 'city') {
        newState.netSuiteCustomerId = ''
        newState.nsCustomerId = ''
        newState.siteName = ''
      } else if (name === 'region') {
        newState.netSuiteCustomerId = ''
        newState.nsCustomerId = ''
        newState.siteName = ''
        newState.city = ''
      } else if (name === 'country') {
        newState.netSuiteCustomerId = ''
        newState.nsCustomerId = ''
        newState.siteName = ''
        newState.city = ''
        newState.region = ''
      }

      onSelection(newState)
    },

    handleLoadStateData: data => {
      setHasRegionData(data.length > 0)
      return data
    },

    handleSchoolSelect: event => {
      const { options, selectedIndex, value } = event.target
      const text = options[selectedIndex].innerHTML

      const selectedNSCustomer = netSuiteCustomerData.filter(
        nsc => nsc.id === parseInt(value, 10),
      )[0]

      onSelection({
        netSuiteCustomerId: selectedNSCustomer ? selectedNSCustomer.id : 0,
        nsCustomerId: selectedNSCustomer.nsCustomerId || '',
        siteName: selectedNSCustomer.id === 0 ? '' : text,
      })
    },

    handleSchoolNameChange: event =>
      onSelection({ siteName: event.target.value }),

    selectCitiesEndpoint: () => {
      if (region) {
        return `location/cities?country=${country}&region=${region}`
      } else if (!hasRegionData && country) {
        return `location/cities?country=${country}`
      }
      return ''
    },

    selectSchoolsEndpoint: () => {
      if (city) {
        return !hasRegionData
          ? `/netsuitecustomers?country=${country}&city=${city}`
          : `/netsuitecustomers?country=${country}&region=${region}&city=${city}`
      }
      return ''
    },
  }

  return <AssignSchool hasRegionData={hasRegionData} {...props} {...handlers} />
}

AssignSchoolContainer.propTypes = {
  onSelection: PropTypes.func.isRequired,
  siteData: PropTypes.object.isRequired,
}

AssignSchoolContainer.defaultProps = {
  siteData: {},
}

AssignSchoolContainer.displayName = 'AssignSchool'

export default AssignSchoolContainer
