import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { HeaderFive, TextLink } from 'secondstep-components'
import { domains } from '../../config/env'
import {
  LICENSE_MANAGEMENT_ASSIGNED_TEXT,
  LICENSE_MANAGEMENT_EXPIRED_TEXT,
  LICENSE_MANAGEMENT_UNASSIGNED_TEXT,
  ASSIGN_LICENSE_HEADER,
  LICENSE_MANAGEMENT_HEADER,
  ASSIGN_LICENSE_MANAGEMENT_TEXT,
  RETURN_TO_LICENSE_LIST,
  BACK_TO_DASHBOARD,
} from './constants'
import {
  LicenseManagementHeaderBox,
  StyledHeaderText,
  LicenseStatusBox,
  BackLink,
  StyledMicroText,
  StyledMicroTextContainer,
} from './component.styles'

import AssignLicense from 'components/AssignLicense'
import Container from 'components/Container'
import LicenseLists from 'components/LicenseLists'
import LicensesStatus from 'components/LicensesStatus'
import { paths } from '../../components/LUMRouter/paths'

export const LicenseManagement = ({
  clearSelectedLicense,
  currentUser,
  onSelectAssign,
  onSelectLicense,
  onSelectUserManagement,
  selectedLicense,
  userLicenses,
  history,
}) => {
  const { country, region, city, netSuiteCustomerId, siteName } = currentUser
  const defaultSiteData = {
    country,
    region,
    city,
    netSuiteCustomerId,
    siteName,
  }
  let pathName = history.location.pathname
  const getHeaderText = () => {
    if (pathName === paths.ASSIGN_LICENSE) {
      if (!selectedLicense) return LICENSE_MANAGEMENT_HEADER
      return ASSIGN_LICENSE_HEADER
    } else return LICENSE_MANAGEMENT_HEADER
  }

  const getHeaderMicroText = () => {
    switch (pathName) {
      case paths.ASSIGN_LICENSE:
        if (!selectedLicense) return LICENSE_MANAGEMENT_UNASSIGNED_TEXT
        return ASSIGN_LICENSE_MANAGEMENT_TEXT

      case paths.ASSIGNED_LICENSES:
        return LICENSE_MANAGEMENT_ASSIGNED_TEXT

      case paths.EXPIRED_LICENSES:
        return LICENSE_MANAGEMENT_EXPIRED_TEXT

      default:
        return LICENSE_MANAGEMENT_UNASSIGNED_TEXT
    }
  }
  return (
    <>
      <Container>
        <LicenseStatusBox>
          {pathName === paths.ASSIGN_LICENSE ? (
            <TextLink
              dataTestId="textlink-back-to-licence-list"
              onClick={clearSelectedLicense}
            >
              <BackLink href={`${domains.ADMIN_DOMAIN}/license-management`}>
                {RETURN_TO_LICENSE_LIST}
              </BackLink>
            </TextLink>
          ) : (
            <BackLink href={domains.LEARN_APP_DOMAIN}>
              {BACK_TO_DASHBOARD}
            </BackLink>
          )}
          <LicensesStatus
            dataTestId="license-status-progressbar"
            licenses={userLicenses.activeLicenses}
            licensesUnassigned={userLicenses.unassignedLicenses}
          />
        </LicenseStatusBox>
      </Container>
      <Container>
        <LicenseManagementHeaderBox>
          <StyledHeaderText>{getHeaderText()}</StyledHeaderText>
          <StyledMicroTextContainer>
            <StyledMicroText selectedLicense={selectedLicense}>
              {getHeaderMicroText()}
            </StyledMicroText>
            {selectedLicense && (
              <span>
                &nbsp;{selectedLicense.product.sku.description} License
              </span>
            )}
          </StyledMicroTextContainer>
        </LicenseManagementHeaderBox>
      </Container>
      {selectedLicense && (
        <Container>
          <HeaderFive>{selectedLicense.productName}</HeaderFive>
          <AssignLicense
            clearSelectedLicense={clearSelectedLicense}
            defaultAdminEmail={currentUser.email}
            defaultSiteData={defaultSiteData}
            license={selectedLicense}
          />
        </Container>
      )}
      {!selectedLicense && (
        <Container>
          <LicenseLists
            licenses={userLicenses.activeLicenses}
            licensesExpired={userLicenses.expiredLicenses}
            licensesUnassigned={userLicenses.unassignedLicenses}
            onSelectAssign={onSelectAssign}
            onSelectLicense={onSelectLicense}
            onSelectUserManagement={onSelectUserManagement}
          />
        </Container>
      )}
    </>
  )
}

LicenseManagement.propTypes = {
  clearSelectedLicense: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onSelectAssign: PropTypes.func.isRequired,
  onSelectLicense: PropTypes.func.isRequired,
  onSelectUserManagement: PropTypes.func.isRequired,
  selectedLicense: PropTypes.object,
  userLicenses: PropTypes.object.isRequired,
}

export default withRouter(LicenseManagement)
