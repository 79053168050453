import React from 'react'
import PropTypes from 'prop-types'
import withData from 'lib/withData'
import FormGroup from 'components/FormGroup'
import Label from 'components/Label'
import { ErrorBoundary, HeaderFive } from 'secondstep-components'
import {
  AssignSchoolFieldBox,
  AssignSchoolContainer,
  Input,
  AssignSchoolSelect,
} from './component.styles'
import {
  ASSIGN_SCHOOL_HEADER,
  CITY_SELECT_DATA_TEST_ID,
  CITY_SELECT_LABEL,
  COUNTRY_SELECT_DATA_TEST_ID,
  COUNTRY_SELECT_LABEL,
  REGION_SELECT_DATA_TEST_ID,
  REGION_SELECT_LABEL,
  SCHOOL_IS_NOT_LISTED_WARNING,
  SCHOOL_SELECT_DATA_TEST_ID,
  SCHOOL_NAME_SELECT_LABEL,
  SCHOOL_SELECT_LABEL,
} from './constants'

const SelectWithData = withData(AssignSchoolSelect)

const AssignSchool = ({
  handleChange,
  handleLoadStateData,
  handleSchoolNameChange,
  handleSchoolSelect,
  hasRegionData,
  getNetSuiteCustomerData,
  selectCitiesEndpoint,
  selectSchoolsEndpoint,
  siteData,
}) => {
  const { city, country, netSuiteCustomerId, siteName, region } = siteData

  return (
    <ErrorBoundary>
      <AssignSchoolContainer>
        <AssignSchoolFieldBox>
          <SelectWithData
            apiEndpoint="/location/countries"
            dataTestId={COUNTRY_SELECT_DATA_TEST_ID}
            label={COUNTRY_SELECT_LABEL}
            name="country"
            onChange={handleChange}
            value={country}
          />
        </AssignSchoolFieldBox>
        <AssignSchoolFieldBox>
          <SelectWithData
            apiEndpoint={country ? `/location/regions?country=${country}` : ''}
            dataTestId={REGION_SELECT_DATA_TEST_ID}
            disabled={!hasRegionData}
            label={REGION_SELECT_LABEL}
            name="region"
            onChange={handleChange}
            onLoadData={handleLoadStateData}
            value={region}
          />
        </AssignSchoolFieldBox>
      </AssignSchoolContainer>
      <AssignSchoolContainer>
        <AssignSchoolFieldBox>
          <SelectWithData
            apiEndpoint={selectCitiesEndpoint()}
            dataTestId={CITY_SELECT_DATA_TEST_ID}
            disabled={hasRegionData && !region}
            label={CITY_SELECT_LABEL}
            name="city"
            onChange={handleChange}
            value={city}
          />
        </AssignSchoolFieldBox>
        <AssignSchoolFieldBox>
          <SelectWithData
            apiEndpoint={selectSchoolsEndpoint()}
            dataTestId={SCHOOL_SELECT_DATA_TEST_ID}
            label={SCHOOL_SELECT_LABEL}
            name="id"
            onChange={handleSchoolSelect}
            onLoadData={data => getNetSuiteCustomerData(data)}
            renderOptions={value => (
              <option key={value.id} value={value.id}>
                {value.companyName}
              </option>
            )}
            value={netSuiteCustomerId}
            warning={
              netSuiteCustomerId === 0 ? SCHOOL_IS_NOT_LISTED_WARNING : null
            }
          />
        </AssignSchoolFieldBox>
      </AssignSchoolContainer>
      {netSuiteCustomerId === 0 && (
        <FormGroup>
          <HeaderFive>{ASSIGN_SCHOOL_HEADER}</HeaderFive>
          <Label for="siteName">{SCHOOL_NAME_SELECT_LABEL}</Label>
          <Input
            id="siteName"
            name="siteName"
            onChange={handleSchoolNameChange}
            value={siteName}
          />
        </FormGroup>
      )}
    </ErrorBoundary>
  )
}

AssignSchool.propTypes = {
  getNetSuiteCustomerData: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLoadStateData: PropTypes.func.isRequired,
  handleSchoolNameChange: PropTypes.func.isRequired,
  handleSchoolSelect: PropTypes.func.isRequired,
  hasRegionData: PropTypes.bool.isRequired,
  selectCitiesEndpoint: PropTypes.func.isRequired,
  selectSchoolsEndpoint: PropTypes.func.isRequired,
  siteData: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    netSuiteCustomerId: PropTypes.number,
    nsCustomerId: PropTypes.string,
    region: PropTypes.string,
    siteName: PropTypes.string,
  }),
}

export default AssignSchool
