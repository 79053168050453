import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Label = styled.label`
  box-sizing: border-box;
  color: ${props =>
    props.disabled
      ? themeGet('colors.backgroundNeutralGrayOneAccent')
      : themeGet('colors.darkGray')};
  font-weight: 700;
  margin-bottom: 0.5rem;
`
