import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { operations as licenseOperations } from 'store/licenseManager'
import { validateEmail } from 'lib/util'
import { EDITABLE_LICENSE_FIELDS } from './data'
import AssignLicense from './component'
import { Skus } from './constants'

const AssignLicenseContainer = ({
  clearSelectedLicense,
  defaultAdminEmail,
  defaultSiteData,
  license,
  sendLicenseAssignment,
}) => {
  const [state, setState] = useState({
    city: '',
    country: '',
    isAssignmentConfirmed: false,
    isReadyToSubmit: false,
    isTosConfirmed: false,
    netSuiteCustomerId: '',
    nsCustomerId: '',
    region: '',
    siteName: '',
  })

  useEffect(() => {
    const {
      city,
      country,
      netSuiteCustomerId,
      region,
      siteName,
    } = defaultSiteData
    setState(prevState => ({
      ...prevState,
      city,
      country,
      region,
      netSuiteCustomerId:
        netSuiteCustomerId || (!netSuiteCustomerId && siteName ? 0 : ''),
      siteName,
    }))
  }, [defaultSiteData])

  useEffect(() => {
    validateReadyToSubmit()
  }, [
    state.city,
    state.country,
    state.isAssignmentConfirmed,
    state.isTosConfirmed,
    state.netSuiteCustomerId,
    state.nsCustomerId,
    state.region,
    state.siteName,
  ])

  const handleChange = data => {
    const updatedState = {}

    EDITABLE_LICENSE_FIELDS.forEach(prop => {
      if (data.hasOwnProperty(prop)) {
        updatedState[prop] = data[prop]
      }
    })

    if (data.hasOwnProperty('email')) {
      updatedState.email = validateEmail(data.email) ? data.email : undefined
    }

    setState(prevState => ({
      ...prevState,
      ...updatedState,
    }))
  }

  // handles isAssignmentConfirmed, and isTosConfirmed
  const handleToggle = data => {
    const updatedState = {}

    if (state.hasOwnProperty(data.target.name)) {
      updatedState[data.target.name] = !state[data.target.name]
    }

    setState(prevState => ({
      ...prevState,
      ...updatedState,
    }))
  }

  const validateReadyToSubmit = () => {
    const {
      nsCustomerId,
      siteName,
      isAssignmentConfirmed,
      isTosConfirmed,
    } = state

    const { skuCode } = license?.product
    const shouldIgnoreTos =
      skuCode !== Skus.MSPSkuCode && skuCode !== Skus.K8SkuCode
    const isTosValid = shouldIgnoreTos || isTosConfirmed
    const isSiteIdSet =
      nsCustomerId !== '' || (nsCustomerId === '' && siteName !== '')

    setState(prevState => ({
      ...prevState,
      isReadyToSubmit:
        isSiteIdSet && !!siteName && isAssignmentConfirmed && isTosValid,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (state.isReadyToSubmit) assignSchoolAndAdmin()
  }

  const assignSchoolAndAdmin = async () => {
    const {
      city,
      country,
      isTosConfirmed,
      nsCustomerId,
      region,
      siteName,
    } = state

    const licenseAssignmentRequestBase = {
      adminEmail: defaultAdminEmail,
      city,
      country,
      siteName,
      ...(region && { region }),
      sku: license?.product?.sku?.skuValue,
      subscriptionId: license.subscriptionId,
      isTosConfirmed,
    }

    const licenseAssignmentRequest = {
      ...licenseAssignmentRequestBase,
      nsCustomerId: nsCustomerId === 0 ? null : nsCustomerId,
    }

    await sendLicenseAssignment(licenseAssignmentRequest)
    clearSelectedLicense()
  }

  const {
    city,
    country,
    isAssignmentConfirmed,
    isReadyToSubmit,
    isTosConfirmed,
    netSuiteCustomerId,
    nsCustomerId,
    region,
    siteName,
  } = state
  const { skuCode } = license.product
  const siteData = {
    city,
    country,
    netSuiteCustomerId,
    nsCustomerId,
    region,
    siteName,
  }
  const isSelaLicense = skuCode === Skus.ADLSkuCode
  const enableTermsOfService =
    skuCode === Skus.MSPSkuCode ||
    skuCode === Skus.K8SkuCode ||
    skuCode === Skus.K8FamilySkuCode ||
    skuCode === Skus.HighSchoolSkuCode ||
    skuCode === Skus.K12SkuCode ||
    skuCode === Skus.K12FamamilySkuCode ||
    isSelaLicense

  return (
    <AssignLicense
      clearSelectedLicense={clearSelectedLicense}
      enableTermsOfService={enableTermsOfService}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleToggle={handleToggle}
      isAssignmentConfirmed={isAssignmentConfirmed}
      isReadyToSubmit={isReadyToSubmit}
      isSelaLicense={isSelaLicense}
      isTosConfirmed={isTosConfirmed}
      siteData={siteData}
    />
  )
}

AssignLicenseContainer.propTypes = {
  clearSelectedLicense: PropTypes.func.isRequired,
  defaultAdminEmail: PropTypes.string,
  defaultSiteData: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    netSuiteCustomerId: PropTypes.number,
    region: PropTypes.string,
    siteName: PropTypes.string,
  }),
  fetchLicenses: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
  sendLicenseAssignment: PropTypes.func.isRequired,
}

AssignLicenseContainer.defaultProps = {
  defaultSiteData: {
    country: '',
    region: '',
    city: '',
    netSuiteCustomerId: '',
    siteName: '',
  },
  defaultAdminValues: {
    email: '',
  },
}

const mapDispatchToProps = {
  sendLicenseAssignment: licenseOperations.sendLicenseAssignment,
}

export default connect(null, mapDispatchToProps)(AssignLicenseContainer)
