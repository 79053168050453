/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const LevelingIcon = ({ className }) => (
  <svg
    className={className}
    height="18px"
    version="1.1"
    viewBox="0 0 17 18"
    width="17px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Leveling Icon</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_icon_leveling"
      stroke="none"
      strokeWidth="1"
    >
      <g fill="#3C6E90" id="Group-17" transform="translate(0, 1)">
        <rect height="2" id="Rectangle" rx="1" width="17" x="0" y="1" />
        <rect height="2" id="Rectangle" rx="1" width="17" x="0" y="7" />
        <rect height="2" id="Rectangle" rx="1" width="17" x="0" y="13" />
        <ellipse
          cx="12"
          cy="2"
          id="Oval"
          rx="1"
          ry="2"
          stroke="#3C6E90"
          strokeWidth="0.75"
        />
        <ellipse
          cx="7"
          cy="8"
          id="Oval"
          rx="1"
          ry="2"
          stroke="#3C6E90"
          strokeWidth="0.75"
        />
        <ellipse
          cx="13"
          cy="14"
          id="Oval"
          rx="1"
          ry="2"
          stroke="#3C6E90"
          strokeWidth="0.75"
        />
      </g>
    </g>
  </svg>
)

LevelingIcon.propTypes = {
  className: PropTypes.string,
}

export default LevelingIcon
