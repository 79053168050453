export const Skus = {
  ADLSkuCode: 'ADL',
  BPUSkuCode: 'BPU',
  CPUSkuCode: 'CPU',
  HighSchoolSkuCode: 'HSP',
  K12FamamilySkuCode: 'K12P',
  K12SkuCode: 'K12P',
  K5SkuCode: 'K5P',
  K8FamilySkuCode: 'K8P',
  K8SkuCode: 'K8P',
  MSPSkuCode: 'MSP',
}
