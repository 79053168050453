import React from 'react'
import PropTypes from 'prop-types'
import {
  AssignLicenseButtonBox,
  AssignLicenseContainedButton,
  AssignLicenseOutlinedButton,
} from './component.styles'
import { ErrorBoundary } from 'secondstep-components'
import AssignConfirm from 'components/AssignConfirm'
import AssignSchool from 'components/AssignSchool'

const AssignLicense = ({
  clearSelectedLicense,
  enableTermsOfService,
  handleChange,
  handleToggle,
  handleSubmit,
  isAssignmentConfirmed,
  isReadyToSubmit,
  isSelaLicense,
  isTosConfirmed,
  siteData,
}) => {
  return (
    <ErrorBoundary>
      <AssignSchool onSelection={handleChange} siteData={siteData} />
      <AssignConfirm
        enableTermsOfService={enableTermsOfService}
        handleToggle={handleToggle}
        isAssignmentConfirmed={isAssignmentConfirmed}
        isSelaLicense={isSelaLicense}
        isTosConfirmed={isTosConfirmed}
      />
      <AssignLicenseButtonBox>
        <AssignLicenseOutlinedButton
          dataTestId="button-cancel-assign-license"
          onClick={clearSelectedLicense}
        >
          Cancel
        </AssignLicenseOutlinedButton>
        <AssignLicenseContainedButton
          dataTestId="button-assign-license"
          disabled={
            !isReadyToSubmit || (enableTermsOfService && !isTosConfirmed)
          }
          onClick={handleSubmit}
        >
          Assign License
        </AssignLicenseContainedButton>
      </AssignLicenseButtonBox>
    </ErrorBoundary>
  )
}

AssignLicense.propTypes = {
  clearSelectedLicense: PropTypes.func.isRequired,
  enableTermsOfService: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isAssignmentConfirmed: PropTypes.bool.isRequired,
  isReadyToSubmit: PropTypes.bool.isRequired,
  isSelaLicense: PropTypes.bool.isRequired,
  isTosConfirmed: PropTypes.bool.isRequired,
  siteData: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    netSuiteCustomerId: PropTypes.number,
    nsCustomerId: PropTypes.string,
    region: PropTypes.string,
    siteName: PropTypes.string,
  }),
}

export default AssignLicense
